import React from "react"
import { Link } from "gatsby"
import { pagination } from "../../config/Functions"
import "./pagination.scss"

const Pagination = ({ pageNumber, total, limit, linkSchema }) => {
  const lastPage = Math.ceil(total / limit)
  const paginationArr = pagination(pageNumber, lastPage)
  return (
    <div className="pagination-div">
      {paginationArr.length > 1 && (
        <div className="pagination-item">
          <ul>
            {pageNumber === 1 ? (
              <Link>
                <li className="disabled previous">‹‹</li>
              </Link>
            ) : (
              <Link to={`${linkSchema}/${1}`}>
                <li className="previous">‹‹</li>
              </Link>
            )}

            {pageNumber === 1 ? (
              <Link>
                <li className="disabled">‹</li>
              </Link>
            ) : (
              <Link to={`${linkSchema}/${pageNumber - 1}`}>
                <li>‹</li>
              </Link>
            )}

            {paginationArr.length > 1 &&
              paginationArr.map((item, index) => {
                return (
                  <Link to={`${linkSchema}/${item}`} key={index}>
                    <li className={item === pageNumber ? "active" : ""}>
                      {item}
                    </li>
                  </Link>
                )
              })}
            {pageNumber < lastPage && (
              <Link to={`${linkSchema}/${pageNumber + 1}`}>
                <li className="next">›</li>
              </Link>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}
export default Pagination
