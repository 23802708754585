export const pagination = function(c, m) {
  const current = c
  const last = m
  const delta = 1
  const left = current - delta
  const right = current + delta
  const range = []
  const rangeWithDots = []
  let l

  for (let i = 1; i <= last; i++) {
    if (right == 2 && i <= right + 1) {
      range.push(i)
    } else if (i >= left && i <= right) {
      range.push(i)
    }
  }

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}
