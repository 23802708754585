import React from "react"
import { Grid, Typography } from "@mui/material"
import { FaRegCalendarAlt } from "@react-icons/all-files/fa/FaRegCalendarAlt"
import { CgTimelapse } from "@react-icons/all-files/cg/CgTimelapse"
import "./cardDate.scss"

const CardDate = ({
  publishedDate,
  readingTime,
  containerClass,
  cardDateClass,
}) => {
  return (
    <Grid className={`cardDate ${containerClass}`}>
      {publishedDate && (
        <Grid className={`publishedDate ${cardDateClass}`}>
          <FaRegCalendarAlt
            color="#FFA62B"
            fontSize="small"
            className="cardIcon"
          />
          <Typography
            variant="p"
            component="p"
            fontFamily="Roboto Condensed, sans-serif"
            fontWeight={400}
            sx={{ fontSize: { xs: "13px", sm: "8px", md: "16px" } }}
          >
            {" "}
            {new Date(publishedDate).toLocaleDateString("en-US")}
          </Typography>
        </Grid>
      )}
      {readingTime && (
        <Grid className="readingTime">
          <CgTimelapse color="#FFA62B" fontSize="small" className="cardIcon" />
          {/* <Typography
            variant="small"
            component="small"
            fontFamily="Roboto Condensed, sans-serif"
            fontWeight={400}
            sx={{
              marginLeft: "5px",
              fontSize: { xs: "13px", sm: "8px", md: "16px" },
            }}
          >
            Reading time:
          </Typography> */}
          <Typography
            variant="small"
            component="small"
            fontFamily="Roboto Condensed, sans-serif"
            fontWeight={700}
            sx={{
              marginLeft: "5px",
              fontSize: { xs: "13px", sm: "8px", md: "16px" },
            }}
          >
            {readingTime} {readingTime > 1 ? "mins" : "min"}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default CardDate
