import React from "react"
import { Link } from "gatsby"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  Divider,
  Grid,
  Box,
  Stack,
} from "@mui/material"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import CardDate from "../UI/CardDate/CardDate"
import NoImg from "../../images/gallery/NoPreviewImage.png"
import "./articleCard.scss"

let getSlug = require("speakingurl")

const ArticleCards = ({
  data,
  key,
  title,
  url,
  img,
  description,
  wallabag_created_at,
  reading_time,
  tags,
}) => {
  const articleUrl = `/post/${getSlug(data ? data.hit.title : title)}`

  const addDefaultSrc = (ev) => {
    ev.target.src = NoImg
  }

  const picture = data && data.hit.preview_picture
  const cardTagsItems = tags.slice(0, 3)

  return (
    <Card className="articleCardContainer">
      <>
        {data &&
          data.hit.tags.slice(0, 3).map((tag, i) => (
            <Link
              to={`/tags/${tag
                .split(" ")
                .join(".")
                .replace(/[^a-zA-Z ]/g, "-")}/1`}
            >
              <Chip
                key={i}
                label={tag.toUpperCase()}
                size="small"
                className="cardTag"
              />
            </Link>
          ))}
        {cardTagsItems &&
          cardTagsItems.map((tag, index) => (
            <Link
              to={`/tags/${tag
                .split(" ")
                .join(".")
                .replace(/[^a-zA-Z ]/g, "-")}/1`}
            >
              <Chip
                label={tag}
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={700}
                sx={{
                  justifyContent: "start",
                  padding: 0,
                  textOverflow: "initial",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  width: "30%",
                  height: "20px",
                  backgroundColor: index % 2 === 0 ? "#163BBF" : "#1B4AF0",
                  borderRadius: "0px",
                  borderTopRightRadius:
                    index === cardTagsItems.length - 1 ? "10px" : "0px",
                  borderBottomRightRadius:
                    index === cardTagsItems.length - 1 ? "10px" : "0px",
                  borderColor: "transparent",
                  fontSize: { xs: "10px", sm: "10px", md: "8px", lg: "15px" },
                  color: "#fff",
                  "& .MuiChip-label": {
                    padding: "0px 0px 0px 5px",
                  },
                }}
              />
            </Link>
          ))}
      </>
      <Link to={articleUrl}>
        <CardMedia
          className="cardImage"
          component="img"
          image={data ? data.hit.preview_picture : img}
          onError={addDefaultSrc}
        />
      </Link>

      <Box sx={{ padding: "0 10px" }}>
        <Link to={articleUrl}>
          <Box className="cardHeader">
            <Typography className="title">
              {data ? data.hit.title : title}
            </Typography>
          </Box>

          <Box>
            <CardDate
              publishedDate={
                data ? data.hit.wallabag_created_at : wallabag_created_at
              }
              readingTime={data ? data.hit.reading_time : reading_time}
            />
          </Box>

          <Box
            className="descriptionContainer"
            sx={{ height: "40px", marginBottom: "10px" }}
          >
            {description && description !== "[embedded content]" ? (
              <Typography className="cardDescription">{description}</Typography>
            ) : (
              !description && (
                <Typography className="cardDescription">
                  No description provided
                </Typography>
              )
            )}
            {data && data.hit.description !== "[embedded content]" && (
              <Typography className="cardDescription">
                {data.hit.description}
              </Typography>
            )}
          </Box>
        </Link>
      </Box>
    </Card>
  )
}
export default ArticleCards
