import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import Layout from "../components/Layout/Layout"
import Pagination from "../components/Pagination/Pagination"
import PageTitle from "../components/PageTitle/PageTitle"
import ArticleCards from "../components/ArticleCard/ArticleCards"
import { Helmet } from "react-helmet"

const Tags = ({ pageContext }) => {
  const { tag, chunk } = pageContext
  const totalCount = pageContext.totalCount
  const pageDescription = `A collection of ${totalCount} post${
    totalCount == 1 ? "" : "s"
  }`

  const paginationInfo = {
    pageNumber: pageContext.currentPage,
    total: pageContext.totalCount,
    limit: pageContext.limit,
    linkSchema: pageContext.url,
  }

  return (
    <Layout>
      <Helmet>
        <title>Cassandra Link - {tag}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={`Cassandra Link - ${tag} `} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://cassandra.link/tags/${tag}`}
        />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Cassandra Link - ${tag} `} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Tags - Cassandra Link",
            keywords: "Tags, Cassandra Link",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <PageTitle title={`${tag.toUpperCase()} ARTICLES`} />
      <Box
        className="articlesContainer"
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: 1600,
          marginX: "auto",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" paragraph>
          {pageDescription}
        </Typography>
        <Grid container spacing={4}>
          {chunk.map((node) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCards
                  key={node.id}
                  title={node.title}
                  url={node.url}
                  img={node.preview_picture}
                  description={node.description}
                  wallabag_created_at={node.wallabag_created_at}
                  reading_time={node.reading_time}
                  tags={node.tags}
                />
              </Grid>
            )
          })}
          <Grid item xs={12}>
            <Pagination {...paginationInfo} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default Tags
